import React, { useState } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import Icon from "../elements/icon-svg";
import withPreview from "../../utility/with-preview";

const Accordion = ({ data }) => {
  const { model, intestazione, accordionTitolo } = data;
  const modello = model?.value?.[0] || "AccordionProdotto";
  const [open, setOpen] = useState(-1);
  return (
    <section
      className={classNames(
        "section accordion-prodotto wrapper-container",
        modello === "AccordionEditoriale" && "accordion-prodotto--modello-editoriale"
      )}
    >
      <div className="container-fluid">
        {intestazione?.value && (
          <div className="row">
            <div className="col-12">
              <div
                className="accordion-prodotto__intestazione"
                dangerouslySetInnerHTML={{ __html: intestazione.value }}
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <ul className="accordion-prodotto__accordion">
              {accordionTitolo?.map((item, i) => (
                <li className="accordion-prodotto__item" key={i}>
                  <div className="accordion-prodotto__header">
                    <div
                      className="accordion-prodotto__title"
                      dangerouslySetInnerHTML={{ __html: item?.value }}
                    />
                    <button
                      className={classNames("accordion-prodotto__icon", {
                        "accordion-prodotto__icon--open": open === i,
                      })}
                      onClick={() => setOpen(open === i ? -1 : i)}
                      aria-label={open === i ? "Chiudi" : "Apri"}
                    >
                      <Icon
                        name="long-arrow-right-solid"
                        style={{ "--width": "17px", "--height": "20px" }}
                      />
                    </button>
                  </div>
                  <div
                    className={classNames("accordion-prodotto__content", {
                      "accordion-prodotto__content--open": open === i,
                    })}
                    dangerouslySetInnerHTML={{ __html: item?.content?.corpo?.value }}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(Accordion);
export const fragment = graphql`
  fragment AccordionFragment on LiferayAccordion {
    liferayFields {
      siteId
      articleId
    }
    model {
      value
    }
    intestazione {
      value
    }
    accordionTitolo: titolo {
      value
      content {
        corpo {
          value
        }
      }
    }
  }
`;
