import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import { Link } from "../link";
import withPreview from "../../utility/with-preview";
import { dataLayerPush } from "../../utility/dataLayerUtils";

const settings = {
  infinite: false,
  slidesToShow: 1,
  adaptiveHeight: false,
  className: "center",
  centerMode: true,
  responsive: [
    {
      breakpoint: 9999,
      settings: "unslick",
    },
    {
      breakpoint: 992,
      settings: {
        speed: 500,
        swipe: true,
        dots: true,
        arrows: false,
        centerPadding: "30px",
      },
    },
  ],
};

const TessereParticulier = ({ data }) => {
  const { mainTitle, cardTitle, datalayerEvent } = data;

  const handleTesseraClick = (card) => {
    const destination = card?.content?.pageLink?.value?.link;
    if (destination && datalayerEvent?.value) {
      const obj = Object.entries(JSON.parse(datalayerEvent.value)).reduce(
        (res, [key, value]) => ({
          ...res,
          [key]: value.replace(/<DESTINATION>/, destination),
        }),
        {}
      );
      dataLayerPush(obj);
    }
  };

  return (
    <section className="section tessere-particulier wrapper-container">
      <div className="container-fluid">
        {mainTitle?.value && (
          <div className="row">
            <div className="col-12 text-center">
              <h1
                className="section__title tessere-particulier__title pt-4 px-2 pt-lg-2 px-lg-0"
                style={{ "--backcolor": mainTitle.content?.backcolorTitle?.value || "transparent" }}
              >
                {mainTitle?.value}
              </h1>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <Slider
              {...settings}
              className="tessere-particulier__slider d-lg-flex justify-content-center align-items-top"
            >
              {cardTitle.map((card, i) => (
                <div className="tessere-particulier__card" key={i}>
                  <Link
                    className="tessere-particulier__link"
                    to={card?.content?.pageLink?.value?.link}
                    onClick={() => handleTesseraClick(card)}
                  >
                    <div className="tessere-particulier__icon p-4 d-flex justify-content-center">
                      {card.content?.cardIcon?.node && (
                        <GatsbyImage
                          className="icon"
                          image={getImage(card.content.cardIcon.node)}
                          alt="icon"
                          imgStyle={{ width: "120px", height: "120px" }}
                        />
                      )}
                    </div>
                    <div
                      className="tessere-particulier__cardTitle px-2 mt-2"
                      dangerouslySetInnerHTML={{ __html: card.value }}
                    ></div>
                    <div
                      className="tessere-particulier__text my-1 px-2 py-1"
                      dangerouslySetInnerHTML={{ __html: card.content?.cardSubtitle?.value }}
                    ></div>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(TessereParticulier);
export const fragment = graphql`
  fragment TessereParticulierFragment on LiferayTessereParticulier {
    liferayFields {
      siteId
      articleId
    }
    mainTitle {
      value
      content {
        backcolorTitle {
          value
        }
      }
    }
    cardTitle {
      value
      content {
        cardIcon {
          node {
            gatsbyImageData(formats: PNG, width: 100, layout: FIXED)
          }
        }
        cardSubtitle {
          value
        }
        pageLink {
          value {
            ... on LiferayJskLayoutPaginaProdotto {
              link
            }
            ... on LiferayJskLayoutPaginaHub {
              link
            }
            ... on LiferayJskLayoutPaginaGenerica {
              link
            }
            ... on LiferayJskLayoutLandingOfferte {
              link
            }
          }
        }
      }
    }
    datalayerEvent {
      value
    }
  }
`;
