import React, { Fragment, useEffect, useState } from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";
import classNames from "classnames";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import { Link } from "../link";
import Loadable from "@loadable/component";

const Modal = Loadable(() => import("react-overlays/Modal"));

const isAnchor = (ctaLink) => {
  // eslint-disable-next-line no-script-url
  return ctaLink?.startsWith("javascript:anchor_modale");
};

const Modale = ({ data }) => {
  const { idModale, imgMezzoSchermo, titolo, titoloParagrafo } = data;
  const [show, setShow] = useState();

  const renderBackdrop = (props) => (
    <button
      className="modale modale__backdrop"
      open={show}
      onClick={() => {
        setShow(false);
      }}
      {...props}
    />
  );

  useEffect(() => {
    const listener = (e) => {
      if (idModale?.value === e.detail?.modalId) {
        setShow(true);
      }
    };
    document.addEventListener("show-modal-component", listener);
    return () => document.removeEventListener("show-modal-component", listener);
  }, [idModale]);

  const image =
    imgMezzoSchermo?.content?.xlMs?.node &&
    withArtDirection(
      getImage(imgMezzoSchermo.content.xlMs.node),
      [
        imgMezzoSchermo?.content?.sMs?.node && {
          media: "(max-width: 768px)",
          image: getImage(imgMezzoSchermo.content.sMs.node),
        },
        imgMezzoSchermo?.content?.mMs?.node && {
          media: "(max-width: 992px)",
          image: getImage(imgMezzoSchermo.content.mMs.node),
        },
        imgMezzoSchermo?.content?.lMs?.node && {
          media: "(max-width: 1200px)",
          image: getImage(imgMezzoSchermo.content.lMs.node),
        },
      ].filter(Boolean)
    );

  return (
    <Modal
      show={show}
      className="modale-wrapper"
      renderBackdrop={renderBackdrop}
      onHide={() => setShow(false)}
      onClick={() => setShow(false)}
    >
      <div className="modale">
        <div
          className={classNames(
            "modale__content d-flex flex-lg-row justify-content-center",
            imgMezzoSchermo?.content?.allineamentoImg?.value === "bottom"
              ? "flex-column-reverse"
              : "flex-column"
          )}
        >
          <button className="modale__close-btn" onClick={() => setShow(false)}>
            X
          </button>

          <div className="col-lg-6 px-0">
            <GatsbyImage
              className="modale__img"
              image={image}
              alt="Header image"
              style={{
                ...(imgMezzoSchermo?.content?.mMs?.node?.probeInfo?.aspectRatio && {
                  "--padding": `${
                    100 / imgMezzoSchermo?.content?.mMs?.node?.probeInfo?.aspectRatio
                  }%`,
                }),
              }}
            />
          </div>
          <div className="col-lg-6 px-0">
            <div className="modale__text-box">
              {titolo?.value && (
                <h3
                  className={classNames(
                    "modale__title",
                    titolo?.content?.allineamentoTitolo?.value?.[0]
                      ? { sx: "text-start", cx: "text-center", dx: "text-end" }[
                          titolo.content.allineamentoTitolo.value
                        ]
                      : "text-start"
                  )}
                  style={{ color: titolo?.content?.coloreTitolo?.value || "#000000" }}
                >
                  {titolo.value}
                </h3>
              )}
              {titoloParagrafo?.map((paragraph, i) => (
                <Fragment key={i}>
                  <div className="d-flex flex-column flex-lg-row">
                    <div className="modale__paragraph d-flex flex-column">
                      <h4
                        className="modale__paragraph-title text-left font-weight-bold"
                        dangerouslySetInnerHTML={{ __html: paragraph?.value }}
                      />
                      {paragraph?.content?.descrizione?.value && (
                        <div
                          className="modale__paragraph-content"
                          dangerouslySetInnerHTML={{
                            __html: paragraph.content.descrizione.value,
                          }}
                        />
                      )}
                      {paragraph?.content?.nota?.value && (
                        <div
                          className="modale__nota"
                          dangerouslySetInnerHTML={{ __html: paragraph.content.nota.value }}
                        />
                      )}
                    </div>
                  </div>
                  {paragraph?.content?.cta?.value && (
                    <div
                      className={classNames(
                        "modale__cta d-flex flex-row",
                        paragraph?.content?.cta?.content?.allineamentoCta?.value?.[0] === "cx"
                          ? "justify-content-center"
                          : "justify-content-start"
                      )}
                    >
                      {isAnchor(paragraph?.content?.cta?.content?.linkCta?.value) ? (
                        <Link
                          to="#FormContattiAnchor"
                          className="cta-link"
                          style={{
                            "--main-color": paragraph?.content?.cta?.content?.coloreCta?.value,
                            "--hover-color":
                              paragraph?.content?.cta?.content?.coloreCtaHover?.value,
                            "--active-color":
                              paragraph?.content?.cta?.content?.coloreCtaClick?.value,
                          }}
                          onClick={() => setShow(false)}
                        >
                          {paragraph?.content?.cta?.value}
                        </Link>
                      ) : (
                        <Link
                          to={paragraph?.content?.cta?.content?.linkCta?.value}
                          className="cta-link"
                          style={{
                            "--main-color": paragraph?.content?.cta?.content?.coloreCta?.value,
                            "--hover-color":
                              paragraph?.content?.cta?.content?.coloreCtaHover?.value,
                            "--active-color":
                              paragraph?.content?.cta?.content?.coloreCtaClick?.value,
                          }}
                        >
                          {paragraph?.content?.cta?.value}
                        </Link>
                      )}
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withPreview(Modale);
export const fragment = graphql`
  fragment ModaleFragment on LiferayModale {
    liferayFields {
      siteId
      articleId
    }
    idModale {
      value
    }
    imgMezzoSchermo {
      content {
        allineamentoImg {
          value
        }
        lMs {
          node {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        mMs {
          node {
            gatsbyImageData(layout: FULL_WIDTH)
            probeInfo {
              aspectRatio
            }
          }
        }
        sMs {
          node {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        xlMs {
          node {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    titolo {
      value
      content {
        allineamentoTitolo {
          value
        }
        coloreTitolo {
          value
        }
      }
    }
    titoloParagrafo {
      value
      content {
        cta {
          value
          content {
            allineamentoCta {
              value
            }
            coloreCta {
              value
            }
            coloreCtaClick {
              value
            }
            coloreCtaHover {
              value
            }
            linkCta {
              value
            }
            tipoCta {
              value
            }
          }
        }
        descrizione {
          value
        }
        imgInLinea {
          content {
            lIl {
              node {
                contentUrl
              }
            }
            mIl {
              node {
                contentUrl
              }
            }
            sIl {
              node {
                contentUrl
              }
            }
            xlIl {
              node {
                contentUrl
              }
            }
          }
        }
        nota {
          value
          content {
            coloreSfondoNota1 {
              value
            }
            coloreSfondoNota2 {
              value
            }
          }
        }
      }
    }
  }
`;
